<template>
  <div :class="customClass === 'license' ? 'kb-form-item' : 'kb-form-row'">
    <div class="kb-form-column kb-form-column-title">
      <label class="kb-form-label">
        <strong class="kb-form-label-text">{{ title }}</strong>
      </label>
    </div>
    <div class="kb-form-column kb-form-dropdown-group">
      <!-- kb-form-dropdown -->
      <div class="kb-form-dropdown" :class="{ 'is-active' : toggle }">
        <button class="kb-form-dropdown-btn" @click.stop="$emit('update:toggle', !toggle);" :disabled="disabled">
          <span class="text">{{ modelValue || '날짜를 선택해주세요' }}</span><i class="icon-arrow"></i>
        </button>
        <div :class="[toggle ? 'is-active' : 'is-inactive']" class="kb-form-period-date dropdown" style="">
          <div class="dropdown-target" :style="width > 0 ? {width: `${width}px`} : {}" >
            <div class="dropdown-box" @click.stop>
              <!-- calendar-picker-container -->
              <div class="calendar-picker-container">
                <!-- calendar-picker-container > calendar-picker -->
                <div class="calendar-picker">
                  <div class="picker-body" style="">
                    <div class="datepicker-header">
                      <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)"><i class="icon-prev"></i></button>
                      <div class="title">{{ currentYearMonth }}</div>
                      <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)"><i class="icon-next"></i></button>
                    </div>
                    <div class="datepicker-table datepicker-table-week">
                      <table>
                        <thead>
                        <tr>
                          <th>일</th>
                          <th>월</th>
                          <th>화</th>
                          <th>수</th>
                          <th>목</th>
                          <th>금</th>
                          <th>토</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(week, idx) in currentWeeks" :key="idx">
                          <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                            <template v-if="day.month === currentMonth">
                              <button class="kb-btn-day"
                                      :class="{ 'is-active' : modelValue === day.ymdformat }"
                                      :disabled="isPastDay(day)"
                                      @click.prevent.stop="handleSelectDate(day)">
                                <span class="text">{{ day.day }}</span></button>
                            </template>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSide" class="kb-form-side">
        <span class="kb-form-text">까지</span>
      </div>
      <div v-if="selectBox && options && options.length > 0" class="kb-form-dropdown" :class="{'is-active': secondToggle}">
        <button class="kb-form-dropdown-btn" @click.stop="toggleOptions" :disabled="disabled">
          <span class="text">{{ secondValue ? secondValue : '장소를 선택해주세요' }}</span><i class="icon-arrow"></i>
        </button>
        <div class="kb-form-dropdown-options">
          <div class="dropdown-option">
            <ul class="dropdown-option-list">
              <li v-for="(option, index) in options" class="dropdown-option-item" :key="index">
                <a href="javascript:" class="dropdown-option-link"
                   :class="{'is-active': secondValue === option[sequenceKey]}"
                   @click.stop="selectOption(index)">
                  <span class="dropdown-option-text">{{ option[nameKey] }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template v-if="customClass !== 'renew'">
<!--        <span v-if="selectBox" class="kb-form-subtext">{{ modelValue ? modelValue : '날짜를 선택해주세요'  }}/{{ secondValue ? secondValue : '장소를 선택해주세요' }}</span>-->
        <span v-if="selectBox" class="kb-form-subtext">{{ modelValue ? modelValue : '날짜를 선택해주세요'  }}/{{ secondValue ? secondValue : '장소를 선택해주세요' }}</span>
        <span v-if="showSubtext" class="kb-form-subtext">{{ subtext }}</span>
      </template>
      <div v-else class="desc">
        <p class="subtext">{{ subtext }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs, watch} from 'vue';
import {getCalendar, ymdStrToDate} from '@/assets/js/util';

export default {
  name: 'RowDatepicker',
  props: {
    modelValue: String,
    toggle: Boolean,
    title: String,
    selectBox: Boolean,
    placeholder1:String,
    secondValue: [String, Number],
    options: Array,
    secondToggle: Boolean,
    sequenceKey: String,
    nameKey: String,
    placeholder2:String,
    showSubtext: Boolean,
    subtext: String,
    showSide: Boolean,
    disabled: { type:Boolean,  default: false },
    customClass: String,
    width: { type: Number, default: 370}
  },
  emits: ['update:modelValue', 'update:toggle', 'update:secondValue', 'update:secondToggle'],
  setup(props, {emit}) {
    const {toggle} = toRefs(props);
    const currentDate = new Date();
    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));
    const localDt = ref('');
    // eslint-disable-next-line no-unused-vars
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    // selectbox
    const subText = ref('');
    // const selectedValue = computed(() => {
    //   const target = props.options.find(x => {
    //     if (props.sequenceKey) {
    //       if (x[props.sequenceKey] === props.secondValue) return x;
    //     } else {
    //       if (x[props.nameKey] === props.secondValue) return x;
    //     }
    //   });
    //   if(target){
    //     return target[props.nameKey]
    //   }
    //   return props.placeholder2;
    // });
    const selectOption = (index) => {
      if (props.selectBox) {
        if (props.secondValue !== props.options[index][props.sequenceKey]) {
          subText.value = props.options[index][props.nameKey];
          emit('update:secondValue', props.options[index][props.sequenceKey]);
        }
        emit('update:secondToggle', false);
      }
    };

    const toggleOptions = () => {
      if (props.selectBox) {
        emit('update:secondToggle', !props.secondToggle);
      }
    };

    const getNextYear = computed(() => {
      if (currentMonth.value === 12) {
        return currentYear.value + 1;
      } else {
        return currentYear.value;
      }
    });

    // 다음달이 내년일 경우 1월 아니면 다음달
    const getNextMonth = computed(() => {
      if (currentMonth.value === 12) {
        return 1;
      } else {
        return currentMonth.value + 1;
      }
    });

    const nextYear = ref(getNextYear.value);
    const nextMonth = ref(getNextMonth.value);
    const currentYearMonth = computed(() => {
      if (currentMonth.value === 13) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value}월`;
      }
    });

    const changeMonth = (isNext) => {
      if (isNext) {
        if (currentMonth.value === 12) {
          currentYear.value++;
          currentMonth.value = 1;
        } else {
          currentMonth.value++;
        }

        if (nextMonth.value === 12) {
          nextYear.value++;
          nextMonth.value = 1;
        } else {
          nextMonth.value++;
        }
      } else {
        if (currentMonth.value === 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }

        if (nextMonth.value === 1) {
          nextYear.value--;
          nextMonth.value = 12;
        } else {
          nextMonth.value--;
        }
      }
    };

    const init = () => {
      currentYear.value = currentDate.getFullYear();
      currentMonth.value = currentDate.getMonth() + 1;
      nextYear.value = getNextYear.value;
      nextMonth.value = getNextMonth.value;
    };

    const handleSelectDate = (date) => {
      localDt.value = date;
      emit('update:modelValue', localDt.value.ymdformat);
      emit('update:toggle', !toggle.value);
    };

    watch(() => toggle.value, () => {
      if (toggle.value) {
        init();
      }
    });

    const isPastDay = (date) => {
      return currentDate >= ymdStrToDate(date.ymd);
    };

    return {
      localDt, currentWeeks, currentMonth, currentYearMonth,
      selectOption, toggleOptions,
      changeMonth, handleSelectDate,
      isPastDay
    }
  }
};
</script>