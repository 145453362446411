<template>
  <div class="ssm-container" @click="closeAllToggle">

    <div class="ssm-my-comment comment-container" style="border-top:0px !important;">
      <div class="comment-wrapper">

        <article class="comment">


          <div class="educourse-list-container"><!-- list-top -->
            <div class="list-top pb-4">
              <div class="top-column"><p class="title text-muted">총 {{ paging.totalCount }}건</p></div>
              <div>
                <button class="kb-btn kb-btn-sm kb-btn-primary" @click="applyModal=true;"><span class="text">신청하기</span></button>
                <div style="width: 5px; display: inline-block"></div>
                <button class="kb-btn kb-btn-sm  kb-btn-primary" @click="recommandModal=true;"><span class="text">추천하기</span>
                </button>
              </div>
            </div><!-- //list-top --><!-- status-tablelist -->
            <div class="status-tablelist">
              <div class="kb-table kb-table-bordered" style="padding-left: 0px;">
                <table>
                  <colgroup>
                    <col style="width:1%;">
                    <col style="width:9%;">
                    <col style="width:50%;">
                    <col style="width:15%;">
                    <col style="width:15%;">
                    <col style="width:10%;">
                    <col style="width:7%;">
                    <!--                    <col  style="width: 160px;">-->
                    <!--                    <col  style="width: 120px;">-->
                    <!--                    <col  style="width: 120px;">-->
                    <!--                    <col  style="width: 140px;">-->
                  </colgroup>
                  <thead>
                  <tr>
                    <th style="text-align: center;"><span class="th-title">No</span></th>
                    <th style="text-align: center;"><span class="th-title">구분</span></th>
                    <th style="text-align: center;"><span class="th-title">내용</span></th>
                    <th style="text-align: center;"><span class="th-title">작성자</span></th>
                    <th style="text-align: center;"><span class="th-title">추천직원</span></th>
                    <th style="text-align: center;"><span class="th-title">작성일</span></th>
                    <th style="text-align: center;"><span class="th-title"></span></th>
                  </tr>
                  </thead>
                  <!--                  <li v-for="(item, index) in items" class="comment-item" :key="index">-->
                  <tbody style="padding-left: 0px;"><!--v-if-->
                  <!--                  <a v-if="((new Date(item.objBgngDt).getTime() - new Date().getTime())/1000/60 < 15) && item.objEndDt > new Date().getTime() && item.objEndYn === 'N'"-->
                  <!--                     href="javascript:" class="kb-btn kb-btn-primary" @click="enterNowClass(item)"><strong class="text">입장하기</strong></a>-->
                  <template v-for="(item, index) in items" :key="index">
                    <tr style="padding-left: 0px; cursor: pointer;" href="javascript:">
                      <td @click="show_board(item)"><strong class="td-text">{{
                          paging.totalCount - index - (paging.pageSize * (paging.pageNo - 1))
                        }}</strong></td>
                      <td @click="show_board(item)"><strong class="td-text">{{ item.title }}</strong></td>
                      <td @click="show_board(item)"><strong class="td-text">{{ item.cn }}</strong></td>
                      <td style="text-align: center;" @click="show_board(item)"><strong class="td-text">{{ item.lrnerNm + ' /' }} <br> {{ item.deptNm }}</strong></td>
                      <td v-if="item.refPost.length != 10" style="text-align: center;" @click="show_board(item)"><strong class="td-text">{{ item.refPost.split(' ')[0] + ' /' }} <br> {{ item.refPost.split('/')[1] }}</strong></td>
                      <td v-else style="text-align: center;" @click="show_board(item)"><strong class="td-text">-</strong></td>
                      <td style="text-align: center;" @click="show_board(item)"><strong class="td-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</strong></td>
                      <td v-if="session.lrnerId === item.lrnerId" @click.stop="toggleDropdown(index)">
                        <div class="comment-actions" style="padding-top: 0px;">
                          <div class="dropdown" :class="{'is-active': item.toggle}">
                            <button class="dropdown-btn kb-btn-actions"><i class="icon-menu"></i></button>
                            <div class="dropdown-target" style="top: 24px;">
                              <div class="dropdown-box">
                                <ul class="dropdown-option-list">
                                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index)"><span class="dropdown-option-text">수정</span></a></li>
                                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickBoardRemove(index)"><span class="dropdown-option-text">삭제</span></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td v-else><strong class="td-text"></strong></td>
<!--                      <td v-if="session.lrnerId === item.lrnerId && !item.editMode && canWrite" class="comment-actions">-->
<!--                        <div class="dropdown" :class="{'is-active': item.toggle}">-->
<!--                          <button class="dropdown-btn kb-btn-actions" @click.stop="toggleDropdown(index)"><i class="icon-menu"></i></button>-->
<!--                          <div class="dropdown-target" style="top: 24px;">-->
<!--                            <div class="dropdown-box">-->
<!--                              <ul class="dropdown-option-list">-->
<!--                                <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index)"><span class="dropdown-option-text">수정</span></a></li>-->
<!--                                <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickBoardRemove(index)"><span class="dropdown-option-text">삭제</span></a></li>-->
<!--                              </ul>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </td>-->
                    </tr>
                  </template>
                  </tbody>

                </table>
              </div>
            </div>

            <div style="margin-bottom: 30px;"></div>
            <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
          </div>

        </article>
      </div>
    </div>

    <div class="popup-container" :class="{ 'is-active' : showApplyModal }">
      <div class="popup" id="popup-hrdcolud-h0301">
        <div class="popup-inner">
          <header class="popup-header">
            <h3 class="title">강사 신청</h3>
          </header>
          <div class="popup-content">
            <section class="popup-section">
              <header class="section-header"><h4 class="title">정보</h4></header>
              <div class="section-content">
                <div class="kb-form-fields">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">작성자</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.lrnerNm }} / {{ show_board_item.deptNm }}</span>
                      </text>
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">강의타입</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.refTyNm }}</span>
                      </text>
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">희망시기</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.refPost }}</span>
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="popup-section">
              <header class="section-header"><h4 class="title">의견</h4></header>
              <div class="section-content">
                <div class="kb-form-fields">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">강의주제</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.cn }}</span>
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="popup-buttons">
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="showApplyModal = false;"><span class="text">닫기</span></a>
          </div>
        </div>
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click.stop="showApplyModal = false;"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
    </div>

    <div class="popup-container" :class="{ 'is-active' : updateApplyModal }">
      <div class="popup" id="popup-hrdcolud-h0301">
        <div class="popup-inner">
          <header class="popup-header">
            <h3 class="title">강사 신청</h3>
          </header>
          <div class="popup-content">
            <section class="popup-section">
              <header class="section-header">
                <h4 class="title">정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields">
                  <!--                <RowInputText v-model="param.inqTitle" class-lst="column-whole" title="제목" placeholder="강사 신청하기" :disabled="true" />-->
                  <InputSelect
                      v-model:value1="param.inqTyCdDcd"
                      v-model:toggle1="toggles.inqTyCdDcds"
                      title="강의타입"
                      :options1="inqTyCdDcds"
                      :useSubText="false"
                      sequence-key1="cd"
                      nameKey1="cdNm"
                      @update:toggle1="closeToggles(toggles, 'inqTyCdDcds')"
                  />
                  <div class="kb-form-bottomtext">
                    <div class="kb-table-bottom">
                      <div class="texticon"><i class="icon-notice"></i><strong class="subtext">스튜디오 Live : 여의도 KB국민은행 신관 스튜디오 생방송 / 자택 Live : 자택 생방송(필요시 지원기기 발송) / 혼합형(녹화&Live) : 강의(사전녹화) + Q&A(생방송)</strong></div>
                    </div>
                  </div>
                  <RowIsPastDatepicker
                      title="희망시기"
                      v-model="param.tuitFeePayDd"
                      v-model:toggle="toggles.tuitFeePayDd"
                  />
                  <div class="kb-form-bottomtext">
                    <div class="kb-table-bottom">
                      <div class="texticon"><i class="icon-notice"></i><strong class="subtext">희망시기의 경우 담당자와 일정조정 후 변동할 수 있음</strong></div>
                    </div>
                  </div>

                  <!--                <Upload-->
                  <!--                    v-model="file"-->
                  <!--                    title="첨부파일"-->
                  <!--                    placeholder="첨부파일을 등록하세요"-->
                  <!--                    btn-title="첨부파일 등록하기"-->
                  <!--                    :max-qty="10"-->
                  <!--                    sequence-key="comFileAtchSn"-->
                  <!--                />-->
                  <!--                <RowDatepicker-->
                  <!--                    v-model="param.cardAprvDt"-->
                  <!--                    v-model:toggle="payToggles.cardAprvDt"-->
                  <!--                    title="승인 ・ 입금일"-->
                  <!--                    :disabled="disabled"-->
                  <!--                    @update:toggle="$emit('update:cardAprvDtToggle',payToggles.cardAprvDt)"-->
                  <!--                    :width="370"-->
                  <!--                />-->
                </div>
              </div>
            </section>
            <section class="popup-section">
              <header class="section-header">
                <h4 class="title">강의주제</h4>
              </header>
              <div class="section-content">
                <div class="segment-box">
                  <textarea v-model="param.inqCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="강의 주제를 입력해주세요."></textarea>
                  <div class="textarea-bottom divider">
                    <div class="bottom-column">
                      <span class="counter">{{ cnSz }} / 500</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="popup-buttons">
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="clickBoardApplyChange"><span class="text">수정하기</span></a>
          </div>
        </div>
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click.stop="updateApplyModal = false"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
    </div>

    <div class="popup-container" :class="{ 'is-active' : updateRecommandModal }">
      <div class="popup" id="popup-hrdcolud-h0301">
        <div class="popup-inner">
          <header class="popup-header">
            <h3 class="title">강사 추천</h3>
          </header>
          <div class="popup-content">
            <section class="popup-section">
              <header class="section-header"><h4 class="title">정보</h4></header>
              <div class="section-content">
                <div class="kb-form-fields">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">추천직원</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.refPost }}</span>
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="popup-section">
              <header class="section-header">
                <h4 class="title">추천사유</h4>
              </header>
              <div class="section-content">
                <div class="segment-box">
                  <textarea v-model="param.inqCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="추천 사유를 입력해주세요."></textarea>
                  <div class="textarea-bottom divider">
                    <div class="bottom-column">
                      <span class="counter">{{ cnSz }} / 500</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="popup-buttons">
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="clickBoardRecommandChange"><span class="text">수정하기</span></a>
          </div>
        </div>
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click.stop="updateRecommandModal = false;"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
    </div>

    <div class="popup-container" :class="{ 'is-active' : showRecommandModal }">
      <div class="popup" id="popup-hrdcolud-h0301">
        <div class="popup-inner">
          <header class="popup-header">
            <h3 class="title">강사 추천</h3>
          </header>
          <div class="popup-content">
            <section class="popup-section">
              <header class="section-header"><h4 class="title">정보</h4></header>
              <div class="section-content">
                <div class="kb-form-fields">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">작성자</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.lrnerNm }} / {{ show_board_item.deptNm }}</span>
                      </text>
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">추천직원</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.refPost }}</span>
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="popup-section">
              <header class="section-header"><h4 class="title">추천사유</h4></header>
              <div class="section-content">
                <div class="kb-form-fields">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                        class="kb-form-label-text">추천사유</strong></label></div>
                    <div class="kb-form-column column-whole">
                      <text class="kb-form-dropdown-btn"><span class="text">{{ show_board_item.cn }}</span>
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="popup-buttons">
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="showRecommandModal = false;"><span class="text">닫기</span></a>
          </div>
        </div>
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click.stop="showRecommandModal = false;"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
    </div>

  </div>
  <SumgoApplyModal v-if="applyModal" v-model="applyModal" :cttMstSn="48510"/>
  <SumgoRecommandModal v-if="recommandModal" v-model="recommandModal" :cttMstSn="48510"/>
</template>

<script>

// import ProfileImg from '@/components/common/ProfileImg';
import InputSelect from '@/components/support/common/InputSelect';
import {computed, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {
  clearTargetTimeout,
  // getItem,
  isSuccess,
  // isUploadSuccess,
  // lengthCheck,
  timestampToDateFormat
} from '@/assets/js/util';
import {closeToggles, convertCloudFrontUrl, getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_CRSE_DIST_GRP_SEQ_LIST} from '@/store/modules/course/course';
import {
  ACT_DELETE_LRN_BOARD,
  ACT_DELETE_LRN_BOARD_CMNT,
  ACT_DELETE_LRN_BOARD_LK,
  // ACT_GET_LEARN_BOARD_WITH_COMMENT,
  ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST,
  ACT_INSERT_LRN_BOARD_CMNT,
  ACT_INSERT_LRN_BOARD_LK,
  // ACT_UPDATE_LRN_BOARD,
  ACT_UPDATE_LRN_BOARD_CMNT, ACT_UPDATE_LRN_BOARD_SUMGO,
  ACT_UPLOAD_LEARN_BOARD_IMAGE,
} from '@/store/modules/board/board';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  confirmDeleteComment,
  confirmInsertComment,
  confirmUpdateComment,
} from '@/assets/js/modules/board/comment-common';
// import CommonTypeImage from '@/components/common/CommonTypeImage';
import SumgoApplyModal from '@/components/sumgo/SumgoApplyModal';
import SumgoRecommandModal from '@/components/sumgo/SumgoRecommandModal';
import {cttTableNm} from "@/assets/js/modules/hrd/hrd-common";
import RowIsPastDatepicker from '@/components/support/common/RowIsPastDatepicker';
// import store from "@/store";
// import {MUT_SHOW_HRD_BADGE} from "@/store/modules/hrdcloud/hrdcloud";
// import {getHrdBadgeList} from "@/assets/js/modules/hrd/hrd-badge";

export default {
  name: 'SumgoEvent',
  components: {
    // CommonTypeImage,
    CommonPaginationFront,
    // ProfileImg,
    SumgoApplyModal,
    SumgoRecommandModal,
    InputSelect,
    RowIsPastDatepicker
  },
  props: {
    targetItem: Object,
  },
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const {showConfirm, showMessage} = useAlert();
    const currentTimestamp = new Date().getTime();

    const session = computed(() => store.state.auth.session);
    const fileInput = ref(null);
    const fileInputInEdit = ref(null);
    const editingIndex = ref(-1);
    // const file = ref({ files: [], removed: [], binaries: [] });

    const contents = ref('');
    const files = ref([]);
    const applyModal = ref(false);
    const showApplyModal = ref(false);
    const showRecommandModal = ref(false);
    const updateApplyModal = ref(false);
    const updateRecommandModal = ref(false);
    const recommandModal = ref(false);
    const inqTyCdDcds = [{ cd: '2113001', cdNm: '스튜디오Live' }, { cd: '2113002', cdNm: '자택Live' }, { cd: '2113003', cdNm: '혼합형(녹화&Live)' }];

    const loadings = reactive({
      seq: false,
      board: false
    });

    const toggles = reactive({
      seq: false,
      file: false,
    });

    const seqs = ref([]);
    const items = ref([]);
    const show_board_item = ref({});

    const paging = reactive({
      pageNo: 1,
      pageSize: 8,
      // pageSize: 1,
      totalCount: 0
    });

    const distCrseGrpSeqSn = ref(0);
    const targetCrseSeq = computed(() => {
      const target = seqs.value.find(x => distCrseGrpSeqSn.value === x.distCrseGrpSeqSn);
      if (target) return target;
      return {distCrseGrpSeqSn: 0}
    });

    const canWrite = computed(() => targetCrseSeq.value.distCrseGrpSeqSn > 0 && targetCrseSeq.value.bgngDt <= currentTimestamp && targetCrseSeq.value.endDt >= currentTimestamp);


    const changeApplyBoard = () => {
      store.dispatch(`board/${ACT_UPDATE_LRN_BOARD_SUMGO}`, {
        lrnPostSn: show_board_item.value.lrnPostSn,
        params: {
          cn: param.inqCn,
          refTyCdDcd: param.inqTyCdDcd,
          refTyNm: inqTyCdDcds.find(e => e.cd === param.inqTyCdDcd).cdNm,
          refPost: param.tuitFeePayDd
        }
      }).then(res => {
        if (isSuccess(res)) {
          showMessage('수정 완료되었습니다.');
          updateApplyModal.value = false;
          // 수정된 부분만 갈아껴준다.
          // shiftBoardWithComment(index);

        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    const changeRecommandBoard = () => {
      store.dispatch(`board/${ACT_UPDATE_LRN_BOARD_SUMGO}`, {
        lrnPostSn: show_board_item.value.lrnPostSn,
        params: {
          cn: param.inqCn,
          refTyCdDcd: param.inqTyCdDcd,
          refTyNm: show_board_item.value.refTyNm
        }
      }).then(res => {
        if (isSuccess(res)) {
          showMessage('수정 완료되었습니다.');
          updateRecommandModal.value = false;

          // 수정된 부분만 갈아껴준다.
          // shiftBoardWithComment(index);

        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    const clickBoardApplyChange = () => {
      if (applyValidate()) {
        showConfirm({
          title: '강사 신청',
          text: '해당 내용으로 수정하시겠습니까?',
          callback: () => {
            changeApplyBoard();
          }
        });
      }
    }

    const clickBoardRecommandChange = () => {
      if (recommandValidate()) {
        showConfirm({
          title: '강사 추천',
          text: '해당 내용으로 수정하시겠습니까?',
          callback: () => {
            changeRecommandBoard();
          }
        });
      }
    }

    const show_board = (index) => {
      show_board_item.value = index;
      if (index.divCdDcd == '2013012') {
        showApplyModal.value = true;
      }
      else {
        showRecommandModal.value = true;
      }
    }

    const removeBoard = (index) => {
      store.dispatch(`board/${ACT_DELETE_LRN_BOARD}`, items.value[index].lrnPostSn).then(res => {
        if (isSuccess(res)) {
          showMessage(`해당 글이 삭제되었습니다.`);
          paging.pageNo = 1;
          getBoardWithCommentList();
        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    const clickBoardRemove = (index) => {
      showConfirm({
        title: '강사신청&추천',
        text: '해당 글을 삭제하시겠습니까?',
        callback: () => {
          removeBoard(index)
        }
      });
    }

    const toggleCommentInput = (index) => {
      items.value[index].addComment = !items.value[index].addComment;
    }

    const clickFileInput = () => {
      fileInput.value.click();
    }

    const getEventFile = (file, res) => {
      const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1);
      return {
        lrnPostFileAtchSn: 0, itnPath: res.path, etnPath: res.path, url: res.url,
        fileNm: file.name,
        fileTyCdDcd: fileExt, fileTyNm: fileExt,
        fileSz: file.size
      }
    }

    const uploadFile = async () => {
      if (files.value.length > 2) {
        showMessage('이미지는 최대 3개까지만 등록할 수 있습니다.');
        fileInput.value.value = '';
        return;
      }

      if (fileInput.value.files && fileInput.value.files.length > 0) {
        const file = fileInput.value.files[0];
        // 이미지 파일만 체크
        if (file.type.includes('image')) {
          const uploadRes = await store.dispatch(`board/${ACT_UPLOAD_LEARN_BOARD_IMAGE}`, {
            contentsType: 'event',
            files: [file]
          });
          files.value.push(getEventFile(file, uploadRes));

        } else {
          showMessage('이미지는 파일만 등록해 주세요.');
        }
        fileInput.value.value = '';
      }
    }

    const clickFileInputInEdit = (index) => {
      editingIndex.value = index;
      fileInputInEdit.value.click();
    }

    const uploadFileInEdit = async () => {
      if (items.value[editingIndex.value].addedFiles.length + items.value[editingIndex.value].alreadyFiles.length > 2) {
        showMessage('이미지는 최대 3개까지만 등록할 수 있습니다.');
        fileInputInEdit.value.value = '';
        return;
      }
      if (fileInputInEdit.value.files && fileInputInEdit.value.files.length > 0) {
        const file = fileInputInEdit.value.files[0];
        // 이미지 파일만 체크
        if (file.type.includes('image')) {
          const uploadRes = await store.dispatch(`board/${ACT_UPLOAD_LEARN_BOARD_IMAGE}`, {
            contentsType: 'event',
            files: [file]
          });
          items.value[editingIndex.value].addedFiles.push(getEventFile(file, uploadRes));
        } else {
          showMessage('이미지는 파일만 등록해 주세요.');
        }
        fileInputInEdit.value.value = '';
      }
    }


    const removeFile = (fileIdx) => {
      showConfirm({
        title: '이미지 삭제',
        text: '선택된 파일을 삭제하시겠습니까?',
        callback: () => {
          files.value.splice(fileIdx, 1);
        }
      });
    }

    // targetKey => alreadyFiles, addedFiles
    const removedFileInUpdate = (index, targetKey, fileIdx) => {
      showConfirm({
        title: '이미지 삭제',
        text: '선택된 파일을 삭제하시겠습니까?',
        callback: () => {
          if (items.value[index][targetKey][fileIdx].lrnPostFileAtchSn > 0) {
            items.value[index].removedFiles.push(items.value[index][targetKey][fileIdx]);
          }
          items.value[index][targetKey].splice(fileIdx, 1);
        }
      });
    }

    const refineBoard = (item) => {
      item.originCn = item.cn;
      item.comments = item.comments.map(y => ({...y, originCmnt: y.cmnt}));
      item.files = item.files.map(y => ({...y, url: convertCloudFrontUrl(session.value.itn, y, 'itnPath', 'etnPath')}));
      item.alreadyFiles = item.files;
      item.addedFiles = [];
      item.removedFiles = [];
      item.isLike = (item.myLikeCnt > 0);
    }

    // const shiftBoardWithComment = (index) => {
    //   store.dispatch(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT}`, items.value[index].lrnPostSn).then(res => {
    //     if (lengthCheck(res)) {
    //       const item = getItem(res);
    //       item.imgUrl = items.value[index].imgUrl;
    //       refineBoard(item)
    //       // edit-mode, toggle 자동 초기화 된다...
    //       items.value[index] = item;
    //     }
    //   });
    // }

    const getBoardWithCommentList = (isInit) => {
      getListFunc(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST}`, {distCrseGrpSeqSn: distCrseGrpSeqSn.value, ...paging}, items, paging, () => {
        if (isInit) loadings.board = true;
        items.value.forEach(x => {
          refineBoard(x);
        })
      });

    }

    const getCourseSequenceList = (isInit) => {
      getListFunc(`course/${ACT_GET_CRSE_DIST_GRP_SEQ_LIST}`, {distCrseSn: props.targetItem.distCrseSn}, seqs, null, () => {
        if (isInit) {
          // 현재 사용되는 회차를 등록
          if (seqs.value.length > 0) {
            const target = seqs.value.find(x => x.bgngDt <= currentTimestamp && x.endDt >= currentTimestamp);
            if (target) {
              distCrseGrpSeqSn.value = target.distCrseGrpSeqSn;
            } else {
              distCrseGrpSeqSn.value = seqs.value[0].distCrseGrpSeqSn;
            }

            loadings.seq = true;
          }
        }

        if (distCrseGrpSeqSn.value > 0) {
          getBoardWithCommentList(isInit);
        }
      });
    }

    const changeSeq = () => {
      paging.pageNo = 1
      getBoardWithCommentList();
    }

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getBoardWithCommentList();
    }

    const toggleDropdown = (index, idx) => {
      if (idx === undefined) {
        items.value.forEach((x, i) => {
          if (i === index) {
            x.toggle = !x.toggle;
          } else {
            if (x.toggle) x.toggle = false;
          }
          x.comments.forEach(y => {
            if (y.toggle) y.toggle = false;
          });
        });
      } else {
        items.value.forEach((x, i) => {
          if (x.toggle) x.toggle = false;
          x.comments.forEach((y, j) => {
            if (i === index && j === idx) {
              y.toggle = !y.toggle
            } else {
              if (y.toggle) y.toggle = false;
            }
          });
        });
      }
    }

    const applyValidate = () => {
      if (!param.tuitFeePayDd) {
        showMessage('희망 시기를 선택해주세요.');
        return false;
      }
      if (!param.inqCn) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const recommandValidate = () => {
      if (!param.inqCn) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    watch(() => applyModal.value, () => {
      getBoardWithCommentList();
    });

    watch(() => recommandModal.value, () => {
      getBoardWithCommentList();
    });

    watch(() => updateApplyModal.value, () => {
      getBoardWithCommentList();
    });

    watch(() => updateRecommandModal.value, () => {
      getBoardWithCommentList();
    });

    // const insertQaAndFiles = async () => {
    //   if (validate()) {
    //     let qa = await insertQa(param);
    //     if (isSuccess(qa)) {
    //       if (file.value.files.length > 0) {
    //         let fileRes = await insertFileAtch({comInqSn: qa.comInqSn}, file.value.files);
    //         if (isUploadSuccess(fileRes)) {
    //           showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
    //             init();
    //             closeModal();
    //
    //             if (qa.badges && qa.badges.length > 0) {
    //               store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
    //               getHrdBadgeList();
    //             }
    //           });
    //         } else {
    //           showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
    //         }
    //       } else {
    //         showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
    //           init();
    //           closeModal();
    //         });
    //
    //         if (qa.badges && qa.badges.length > 0) {
    //           store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
    //           getHrdBadgeList();
    //         }
    //       }
    //     } else {
    //       showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
    //     }
    //   }
    // };

    const changeMode = (index) => {
      if (items.value[index].divCdDcd == '2013012') {
        show_board_item.value = items.value[index];
        param.inqTyCdDcd = show_board_item.value.refTyCdDcd;
        param.tuitFeePayDd = show_board_item.value.refPost;
        param.inqCn = show_board_item.value.cn;
        updateApplyModal.value = true;
      }
      else if (items.value[index].divCdDcd == '2013013') {
        show_board_item.value = items.value[index];
        param.inqTyCdDcd = show_board_item.value.refTyCdDcd;
        param.tuitFeePayDd = '';
        param.inqCn = show_board_item.value.cn;
        updateRecommandModal.value = true;
      }

      // if (idx === undefined) {
      //   if (!items.value[index].editMode) {
      //     // edit 모드가 풀렸을 때 처리
      //     items.value[index].cn = items.value[index].originCn;
      //     items.value[index].alreadyFiles = items.value[index].files;
      //     items.value[index].removedFiles = [];
      //     items.value[index].toggle = false;
      //   }
      //   items.value[index].editMode = !items.value[index].editMode;
      // } else {
      //   if (!items.value[index].comments[idx].editMode) {
      //     items.value[index].comments[idx].cmnt = items.value[index].comments[idx].originCmnt;
      //     items.value[index].comments[idx].toggle = false;
      //   }
      //   items.value[index].comments[idx].editMode = !items.value[index].comments[idx].editMode;
      // }
    }

    const isLoading = ref(false);

    const clickCommentAdd = (index) => {
      const params = {
        lrnPostSn: items.value[index].lrnPostSn,
        params: {cmnt: items.value[index].cmnt}
      };

      confirmInsertComment(`board/${ACT_INSERT_LRN_BOARD_CMNT}`, params, isLoading.value, items.value[index].cmnt, async (res) => {
        if (res.lrnPostCmntSn > 0) {
          const pushItem = {
            lrnPostCmntSn: res.lrnPostCmntSn,
            cmnt: items.value[index].cmnt,
            originCmnt: items.value[index].cmnt,
            cmntRegDt: new Date().getTime(),
            cmntLrnerId: session.value.lrnerId,
            cmntLrnerNm: session.value.lrnerNm,
            cmntDeptNm: session.value.deptNm,
            cmntImgUrl: store.state.my.profileImgUrl
          }

          items.value[index].comments.push(pushItem);
          items.value[index].cmnt = '';
        }
      });
    }

    const clickChange = (index, idx) => {
      items.value[index].comments[idx].toggle = false;

      const params = {
        lrnPostCmntSn: items.value[index].comments[idx].lrnPostCmntSn,
        params: {cmnt: items.value[index].comments[idx].cmnt}
      };

      confirmUpdateComment(`board/${ACT_UPDATE_LRN_BOARD_CMNT}`, params, isLoading.value, items.value[index].comments[idx].cmnt, () => {
        items.value[index].comments[idx].originCmnt = items.value[index].comments[idx].cmnt;
        items.value[index].comments[idx].editMode = false;
      });
    }

    const clickRemove = (index, idx) => {
      items.value[index].comments[idx].toggle = false;
      confirmDeleteComment(`board/${ACT_DELETE_LRN_BOARD_CMNT}`, items.value[index].comments[idx].lrnPostCmntSn, isLoading.value, items.value[index].comments);
    }

    const closeAllToggle = () => {
      closeToggles(toggles);

      items.value.forEach(x => {
        if (x.toggle) x.toggle = false;
        x.comments.forEach(y => {
          if (y.toggle) y.toggle = false;
        });
      });
    }

    const toggleBoardLike = (index) => {
      if (!canWrite.value) return;

      clearTargetTimeout(items.value[index].debounce);
      items.value[index].debounce = setTimeout(() => {
        store.dispatch(`board/${items.value[index].isLike ? ACT_INSERT_LRN_BOARD_LK : ACT_DELETE_LRN_BOARD_LK}`, items.value[index].lrnPostSn).then(() => {
        });
      }, 500);

      if (items.value[index].isLike) {
        items.value[index].likeCnt -= 1;
      } else {
        items.value[index].likeCnt += 1;
      }
      items.value[index].isLike = !items.value[index].isLike;
    }

    const getRefinedText = cn => {
      if (cn) {
        // \n을 <br>로 치환
        cn = cn.replace(/(?:\r\n|\r|\n)/g, '<br>');
        // return cn.replace(/(<([^>]+)>)/gi, "");
      }
      return cn;
    }

    getCourseSequenceList(true);

    watch(() => props.targetItem, () => {
      if (props.targetItem && props.targetItem.distCrseSn > 0) {
        paging.pageNo = 1;
        getCourseSequenceList(true);
      }
    });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const param = reactive({
      tblNm: cttTableNm,
      tblSn: 0,
      comInqSn: 0,
      // inqTitle: '강사 신청하기',
      inqTyCdDcd: '2113001',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: '',
      tuitFeePayDd: '',
    });

    return {
      fileInput,
      fileInputInEdit,
      session,
      toggles,
      loadings,
      seqs,
      distCrseGrpSeqSn,
      contents,
      files,
      canWrite,
      items,
      paging,
      clickFileInput,
      uploadFile,
      clickFileInputInEdit,
      uploadFileInEdit,
      toggleCommentInput,
      pagingFunc,
      clickBoardApplyChange,
      clickBoardRecommandChange,
      clickBoardRemove,
      toggleDropdown,
      changeMode,
      clickCommentAdd,
      clickChange,
      clickRemove,

      changeSeq,
      removeFile,
      removedFileInUpdate,
      getRefinedText,

      timestampToDateFormat,
      closeAllToggle,
      toggleBoardLike,
      isMobile,
      applyModal,
      showApplyModal,
      showRecommandModal,
      updateApplyModal,
      updateRecommandModal,
      recommandModal,
      show_board,
      show_board_item,
      cnSz,
      param,
      closeToggles,
      inqTyCdDcds
    }
  }
};
</script>
