<template>
  <main class="kb-main" id="kb-hrdcloud" @click="closeToggles(toggles)">
<!--    <HrdCloudSearchResult v-if="isSearching"-->
<!--                          :keyword="search.keyword"-->
<!--                          :isLoading="isLoading"-->
<!--                          :session="session"-->
<!--                          :hrds="hrds"-->
<!--                          :categories="categories"-->
<!--                          :categories-paging="categoriesPaging"-->
<!--                          :paging="paging"-->
<!--                          :paging-func="pagingFunc"-->
<!--    />-->
<!--    <template v-else>-->
    <lxp-main-header :show-breadcrumb="true" :show-custom-title="true" :show-excel-download="false" :title="`숨은 고수를 찾아라`" >

    </lxp-main-header>

    <div class="main-content main-component">
      <div class="kb-footer" id="kb-footer">
        <div v-if="session.lrnerId" class="footer-row footer-divider" style="border-top:0px;">
          <div class="footer-notice footer-component">
            <ul class="notice-list swiper-wrapper" style="width: 80%;">
              <swiper

                  :slides-per-view="1"
                  :modules="modules"
                  :navigation="{
                nextEl: '.kb-btn-notice-kb-nav-next',
                prevEl: '.kb-btn-notice-kb-nav-prev'
              }"
              >
                <swiper-slide v-for="(item, idx) in items" :key="idx" class="notice-item swiper-slide"
                              style="margin: 0;">
                  <div class="notice-date">
                    <a href="javascript:" class="link">{{ item.regDt }}</a>
                  </div>
                  <div class="notice-title">
                    <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="link">
                      {{ item.ntcTitle }}
                    </router-link>
                  </div>
                </swiper-slide>
                <!--            </swiper-slide>-->
              </swiper>
            </ul>
            <div class="notice-nav">
<!--              <router-link to="" class="more-link">공지 전체보기</router-link>-->
              <div class="nav">
                <button class="kb-btn-nav kb-btn-kb-nav-prev kb-btn-notice-kb-nav-prev"><i class="icon-arrow-prev"></i></button>
                <button class="kb-btn-nav kb-btn-kb-nav-next kb-btn-notice-kb-nav-next"><i class="icon-arrow-next"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

    <template v-if="tabMenu <= 0">
      <div class="intro-section">
        <CommonBanner
          :banner-code=bannerCode
        />
        <div class="intro-column intro-my">
          <div class="my-info">
            <div class="avatar"><ProfileImg :target="session"/></div>
            <h4 class="name">{{ session.lrnerNm }}</h4>
            <p class="team">{{ session.deptNm }}</p>
          </div>
          <div class="my-service" style="text-align: center;">
              <span class="text">누적 좋아요 갯수 : {{ myLikeTotalCnt }}개</span>
<!--            <p v-if="myLikeTotalCnt != '0'" class="text"> 학습 예정일 : {{ myLikeTotalCnt }}</p>-->

          </div>
        </div>
      </div>

      <div class="intro-section" style="padding-bottom: 20px; border-bottom:1px solid var(--kb-border-color);">

        <div class="kb-main" id = "kb-sdl-ssm">
          <nav class="kb-nav-round-tabs">
            <ul class="kb-nav-list">
              <li class="kb-nav-item">
                <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===1 }" @click="menu=1"><span class="kb-nav-text">강사신청&추천</span></a>
              </li>
              <li class="kb-nav-item">
                <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===2 }" @click="menu=2"><span class="kb-nav-text">숨고Talk</span></a>
              </li>
            </ul>
          </nav>
          <div v-if="menu===1" class="main-content min-component">
            <SumgoEvent
                :target-item="targetItem"
            />
          </div>
          <div v-if="menu===2" class="main-content min-component">
            <SumgoBoard
                :target-item="targetItem2"
            />
          </div>
        </div>

        <div class="intro-column intro-my">
          <div class="my-info" style="padding-top:40px;">
            <div class="title" style=" padding:0px 30px 30px 0px; display:flex;font:normal 18px/1.22 var(--kb-font-KBFGDisplayB);">
              <h3 style="font:inherit; margin-left:8px" >이달의 숨은 고수</h3>
            </div>
            <div class="best-monthly" style="display:flex; width:100%;justify-content: space-evenly;" >
<!--              <div class="mothly-emp" style="width: 10px; height: 10px;"><img src="/app/img/img_empty.30f8e704.png" alt="">-->
<!--                <p class="text">예정된 학습이 없습니다.</p>-->
<!--              </div>-->

              <div v-if="items_live.length !==0 ">
                <div v-for="(items, idx) in items_live" :key="idx"  class="mothly-emp"  style="text-align: -webkit-center;">
                    <div class="avatar" style="width:60px; height:60px;">
                      <CommonTypeImage
                          :img-src="getImgUrl(items.instrId)"
                          theme="profile"
                      />
                    </div>
                    <h4 class="name">{{ items.instrNm }}</h4>
                    <p class="team">{{ items.instrDeptNm }}</p>
                    <p class="team">{{ items.objNm }}</p>
                    <p class="team">{{ timestampToDateFormat(items.objBgngDt, 'yyyy.MM.dd') }} 예정</p>
                </div>
              </div>

              <div v-else class="mothly-emp" >
                <h4 class="name">예정된 연수가 없습니다.</h4>
              </div>

            </div>
          </div>

          <div class="my-info" style="padding-top:40px;">
            <div class="title" style=" padding:0px 30px 30px 0px; display:flex;font:normal 18px/1.22 var(--kb-font-KBFGDisplayB);">
              <h3 style="font:inherit;margin-left:8px" >이번주의 숨고 HOT Talk</h3>
            </div>
            <div class="best-monthly" style="display:flex; width:100%;justify-content: space-evenly;" >

              <div  class="ssm-comment comment-container" style="margin-top:0px; border-top:0px;">
                <div class="comment-wrapper">
                  <ul class="comment-list">
                    <!-- comment-item -->
                    <li class="comment-item" style="border-top:0px;">
                      <!-- comment -->
                      <article class="comment" v-if="weekly_board_first.cn" >
                        <div class="comment-avatar">
                          <div class="avatar" style="width:60px; height:60px;">

                              <CommonTypeImage
                                  :img-src="weekly_board_first.imgUrl"
                                  theme="profile"
                              />

                          </div>
                        </div>

                        <div class="comment-contents">
                          <div class="content-metadata">
                            <div class="metadata">{{ weekly_board_first.lrnerNm }}・{{ weekly_board_first.deptNm}}</div>
                            <div class="metadata metadata-date">좋아요: {{ weekly_board_first.likeCnt }}</div>
                          </div>
                          <div class="content-text">
                            <p class="text" >{{ weekly_board_first.cn }}</p>
                          </div>
                        </div>
                      </article>
                    </li>
                    <li class="comment-item" style="border-top:0px;">

                      <article class="comment" v-if="weekly_board_second.cn">
                        <div class="comment-avatar">
                          <div class="avatar" style="width:60px; height:60px;">

                              <CommonTypeImage
                                  :img-src="weekly_board_second.imgUrl"
                                  theme="profile"
                              />

                          </div>
                        </div>

                        <div class="comment-contents" >
                          <div class="content-metadata">
                            <div class="metadata">{{ weekly_board_second.lrnerNm }}・{{ weekly_board_second.deptNm}}</div>
                            <div class="metadata metadata-date">좋아요: {{ weekly_board_second.likeCnt }}</div>
                          </div>
                          <div class="content-text">
                            <p class="text" >{{ weekly_board_second.cn }}</p>
                          </div>
                        </div>
                      </article>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

<!--          <div class="my-info" style="padding-top:40px;">-->
<!--            <div class="title" style=" padding:0px 30px 30px 0px; display:flex;font:normal 18px/1.22 var(&#45;&#45;kb-font-KBFGDisplayB);">-->
<!--              <h3 style="font:inherit;margin-left:8px" >우리도 고수다!</h3>-->
<!--            </div>-->
<!--            <div class="best-monthly" style="display:flex; width:100%;justify-content: space-evenly;" >-->
<!--              <button> [HOT TIP][핫;팁] 바로가기 </button>-->
<!--            </div>-->
<!--          </div>-->
          <div class="my-info" style="padding-top:40px;">
            <div class="title" style=" padding:0px 30px 30px 0px; display:flex;font:normal 18px/1.22 var(--kb-font-KBFGDisplayB);">
              <h3 style="font:inherit;margin-left:8px" >우리도 고수다!</h3>
            </div>
<!--            <div class="best-monthly" style="display:flex; width:100%;justify-content: space-evenly;" >-->
              <img src="../../../assets/lxp/images/sumgo/hot_tip_banner.png" style="width: 300px;">
<!--            </div>-->
          </div>

          <div class="my-info" style="padding-top:40px;">
            <div class="title" style=" padding:0px 30px 30px 0px; display:flex;font:normal 18px/1.22 var(--kb-font-KBFGDisplayB);">
              <h3 style="font:inherit;margin-left:8px" >또 다른 고수가 궁금해?</h3>
            </div>
            <div class="best-monthly" style="display:flex; width:100%;justify-content: space-evenly; padding-bottom: 40px;" >
              <button class="kb-btn kb-btn-sm kb-btn-primary" style="font-size: 20px;" onclick="location.href='https://lxp.kbstar.com/app/prep/hrdcloud?sortTyCdDcd=2073001&category1=4&pageNo=1'"> Hot Tube 바로가기 </button>
            </div>
          </div>
        </div>
      </div>

    </template>

<!--    <HrdCloudMap2 v-model="cloudMapModal"/>-->

    <section class="hrdcloud section">
      <template v-for="(item, idx) in mainList" :key="idx">
        <article class="content-section" >
          <header class="section-header"><h3 class="title">{{ item.title }}</h3>
            <div class="header-sub">
              <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1:'1',category2:'12',category3:'95' }}" class="more-link">전체보기</router-link>
            </div>
          </header>
          <template v-if="item.items && item.items.length > 0">
            <HrdCloudRow :items="item.items" :show-put="true" :session="session"/>
          </template>
          <template v-else-if="(item.items && item.items.length === 0) && !item.isLoading">
            <div class="search-container">
              <div class="result-empty">
                <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                <p v-if="item.name === 'sumgo'" class="text">콘텐츠가 없습니다.</p>
              </div>
            </div>
          </template>
          <template v-else-if="item.isLoading">
            <LoadingDiv v-model="item.isLoading" />
          </template>
        </article>
      </template>
    </section>


  </div>

  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, reactive, ref} from 'vue';
import CommonBanner from '@/components/common/CommonBanner';
import CommonTypeImage from '@/components/common/CommonTypeImage';
import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
import SumgoEvent from '@/components/sumgo/SumgoEvent';
import {
  closeToggles, convertCloudFrontUrl,
  getDcdOptions,
  getListFunc,
  pushQuery,
} from '@/assets/js/ui.init';
import {
  getCheckItems,
  getItems,
  initParams,
  initProgressBar,
  lengthCheck,
  stringCheck,
  timestampToDateFormat
} from '@/assets/js/util';
import {getHrdCloudList, setMainItems, sortTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {useRoute} from 'vue-router';
import {
  ACT_GET_HRDCLOUD_SUMGO_LIST,
} from '@/store/modules/prep/prep';

import {ACT_GET_HRDCLOUD_CATE_SEARCH_LIST} from '@/store/modules/hrdcloud/hrdcloud';

import ProfileImg from '@/components/common/ProfileImg';
import {
  banerDivCdDcds,
  banerExpsrLocCdDcds,
  getBanerList,
} from '@/assets/js/modules/baner/common-baner';
import {Pagination} from "swiper";
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {ACT_GET_SUMGO_LEARNING_LIVE_LIST} from "@/store/modules/learn/learn";

import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import LoadingDiv from '@/components/common/LoadingDiv';
import SumgoBoard from '@/components/sumgo/SumgoBoard';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST} from "@/store/modules/board/board";
const hrdLoading = ref(false);

export default {
  name: 'HrdCloudMain',
  components: {
    CommonBanner,
    Swiper,
    SwiperSlide,
    SumgoEvent,
    SumgoBoard,
    LxpMainHeader,
    ProfileImg,
    CommonTypeImage,
    LoadingDiv,
    HrdCloudRow
  },
  setup() {
    initProgressBar();
    const store = useStore();
    const route = useRoute();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.sumgo);
    const cloudMapModal = ref(false);
    const items = ref([]);
    const items_tube = ref([]);
    const items_live = ref([]);
    const items_board = ref([]);
    const weekly_board_first = ref({});
    const weekly_board_second = ref({});
    const board_first = ref(0);
    const board_second = ref(0);
    // const item = ref({});
    const mainList = ref([
      { name: 'sumgo', title: '숨은 고수의 영상 보기', params: {}, isLoading: true, items:[], url:  `prep/${ACT_GET_HRDCLOUD_SUMGO_LIST}` }
    ]);
    const isLoading = ref(false);
    const isSearching = computed(() => (route.query && route.query.keyword && route.query.keyword.length > 0));
    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));

    // 순서를 변경할때 getMainList의 idx값도 같이 바꿔줘야함

    const currentTs = ref(new Date().getTime());
    const menu = ref(1);
    const hrdCloudFilter = ref('sumgo');
    const myLikeTotalCnt = ref('0');

    const hrds = ref([]);
    const categories = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 12,
      totalCount: 0
    });
    const tubePaging = reactive({
      pageNo: 1,
      pageSize: 4,
      totalCount: 0
    });
    const paging2 = reactive({
      pageNo: 0,
      pageSize: 9999,
      totalCount: 0
    });
    const categoriesPaging = reactive({
      pageNo: 1,
      pageSize: 9999,
      totalCount: 0
    });

    const targetItem = computed(() => {
      return {
        distCrseSn: 203057,
        learners: []
      }
    });


    const targetItem2 = computed(() => {
      return {
        distCrseSn: 203058,
        learners: []
      }
    });

    const getDateDiff = (d1, d2) => {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      const diffDate = date1.getTime() - date2.getTime();

      return Math.abs(diffDate / (1000 * 60 * 60 * 24));
    }


    const refineBoard = (item) => {
      const currentDate = new Date();

      if (item.lrnerId == session.value.lrnerId) {
        myLikeTotalCnt.value = myLikeTotalCnt.value + item.likeCnt;
      }
      if (item.likeCnt > board_first.value && getDateDiff(currentDate, item.regDt) < 7) {
        board_second.value = board_first.value;
        weekly_board_second.value = weekly_board_first.value;
        weekly_board_first.value = item;
        board_first.value = item.likeCnt;
      }
      else if (item.likeCnt > board_second.value && getDateDiff(currentDate, item.regDt) < 7) {
        weekly_board_second.value = item;
      }

      item.originCn = item.cn;
      item.comments = item.comments.map(y => ({...y, originCmnt: y.cmnt}));
      item.files = item.files.map(y => ({...y, url: convertCloudFrontUrl(session.value.itn, y, 'itnPath', 'etnPath')}));
      item.alreadyFiles = item.files;
      item.addedFiles = [];
      item.removedFiles = [];
      item.isLike = (item.myLikeCnt > 0);
    }

    const getBoardWithCommentList = () => {
      getListFunc(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST}`, {distCrseGrpSeqSn: '15', ...paging2}, items_board, paging2, () => {
        // if(isInit) loadings.board = true;
        myLikeTotalCnt.value = 0;
        board_first.value = 0;
        board_second.value = 0;
        items_board.value.forEach(x => {refineBoard(x);})
      });
    }

    getBoardWithCommentList();

    // 콘텐츠명, 태그, 카테고리로 검색
    // 검색시 영문 대소문자 구분하지 않음
    const search = reactive({
      keyword: '',
      sortTyCdDcd: '',
      category1: 0,
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0
    });

    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });

    // KB Tube
    const uploadModal = reactive({
      isActive: false,
      toggle: () => {
        uploadModal.isActive = !uploadModal.isActive;
      },
    });

    const getList = async () => {
      let contentsList = await getHrdCloudList({...search, ...paging, ...params});
      hrds.value = getCheckItems(contentsList);
      paging.totalCount = contentsList && contentsList.paging ? contentsList.paging.totalCount : 0;
      if (stringCheck(search.keyword)) {
        let categoryList = await store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_SEARCH_LIST}`, {keyword: search.keyword, ...paging});
        categories.value = getCheckItems(categoryList);
        categoriesPaging.totalCount = categoryList && categoryList.paging ? categoryList.paging.totalCount : 0;
      }
      isLoading.value = false;
    };

    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    const getMainList = async () => {

      let sumgo = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_SUMGO_LIST}`, {...tubePaging});
      setMainItems(mainList, sumgo, 0);
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if (pageNo) {
        pushQuery({...search, pageNo});
      } else {
        pushQuery({...search});
      }
    };

    const handleMenuClick = (tabMenu) => {
      hrds.value = [];
      initParams(search);
      search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
      if (tabMenu > 0) {
        search.category1 = tabMenu;
      }
      pagingFunc(1);
    };







    const popups = ref([]);

    //location

    getBanerList({
      banerDivCdDcd: banerDivCdDcds.popup,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.hrdCloud,
      pageNo: 1,
      pageSize: 10
    }, popups);
    let nowDate = new Date();
    nowDate = nowDate.setTime(currentTs.value);

    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-hrdcloud')) > nowDate);

    const getHelpNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_SUMGO_MAIN,
        pageSize: 5
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res).map(x => ({
            ...x,
            regDt: timestampToDateFormat(x.regDt, 'yyyy.MM.dd')
          }));
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getHelpNoticeList();

    const getImgUrl = (lrnerId) => {
      return "https://lxpcms.kbstar.com/contents/emp_img/current/"+ lrnerId + ".jpg"
    };


    getListFunc(`learn/${ACT_GET_SUMGO_LEARNING_LIVE_LIST}`, {}, items_live, null, () => {
    });

    getMainList();

    return {
      isLoading, session, sortTypeDcd, hrds, paging, paging2, cloudMapModal, lochref,
      tabMenu, params, categories, categoriesPaging,
      handleMenuClick,
      mainList,
      search, pagingFunc, isSearching, getList,
      toggles, closeToggles,
      popups,
      expirationPopup,
      uploadModal,
      modules: [Pagination],
      items,
      items_tube,
      items_board,
      items_live,
      weekly_board_first,
      weekly_board_second,
      menu,
      myLikeTotalCnt,
      targetItem,
      targetItem2,
      hrdCloudFilter,
      board_first,
      board_second,
      getImgUrl,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
    }
  }
};
</script>