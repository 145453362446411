<template>
  <div class="ssm-container" @click="closeAllToggle">
    <div v-if="loadings.seq" class="list-top">
      <div class="top-column">
        <!-- list-top > list-sort -->
<!--        <div class="list-sort">-->
<!--          <div class="sort">-->
<!--            &lt;!&ndash; kb-form-dropdown &ndash;&gt;-->
<!--            <SortButtonSelect-->
<!--                v-model="distCrseGrpSeqSn"-->
<!--                v-model:toggle="toggles.seq"-->
<!--                title="목표 등록 여부"-->
<!--                :options="seqs"-->
<!--                sequence-key="distCrseGrpSeqSn"-->
<!--                name-key="distCrseGrpSeqNm"-->
<!--                :option-style="isMobile ? {left: '-10px'} : {}"-->
<!--                @selected="changeSeq"-->
<!--            />-->
<!--            &lt;!&ndash; //kb-form-dropdown &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="ssm-my-comment comment-container">
      <div class="comment-wrapper">
        <article class="comment">

          <template v-if="!isMobile">
            <div class="comment-avatar">
              <div class="avatar">
                <ProfileImg :target="session"/>
              </div>
            </div>
            <div class="comment-contents">
              <div class="content-metadata">
                <div class="metadata is-me">{{ session.lrnerNm }} ・ {{ session.deptNm }}</div>
                <div class="metadata metadata-date"></div>
              </div>
              <div class="content-form" style=" border:2px solid var(--kb-border-color);">
                <textarea v-model.trim="contents" class="kb-form-control" :placeholder="canWrite ? '내용을 입력하세요(300자 이하)' : '이벤트 기간이 아닙니다.'" :disabled="!canWrite" style="padding:10px;"></textarea>
              </div>
              <input ref="fileInput" type="file" @change="uploadFile" style="display: none;"/>

              <div class="content-files ">
                <div v-for="(file, index) in files" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="index" @click="removeFile(index)">
                  <div class="file-link">
                    <img :src="file.url" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="canWrite" class="comment-actions">
<!--              <div class="dropdown" :class="{'is-active': toggles.file}">-->
<!--                <button class="dropdown-btn kb-btn-actions" @click.stop="toggles.file = !toggles.file"><i class="icon-menu"></i></button>-->
<!--                <div class="dropdown-target">-->
<!--                  <div class="dropdown-box">-->
<!--                    <ul class="dropdown-option-list">-->
<!--                      <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickFileInput"><span class="dropdown-option-text">사진앨범</span></a></li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <button class="kb-btn kb-btn-primary" @click="clickBoardAdd"><span class="text">등록</span></button>
            </div>
          </template>

          <template v-else>
            <div class="comment-row">
              <div class="comment-avatar">
                <div class="avatar">
                  <ProfileImg :target="session"/>
                </div>
              </div>
              <div class="comment-contents" >
                <div class="content-metadata">
                  <div class="metadata is-me">{{ session.lrnerNm }} ・ {{ session.deptNm }}</div>
                  <div class="metadata metadata-date"></div>
                </div>
              </div>

            </div>
            <div class="comment-form">
              <textarea v-model.trim="contents" class="kb-form-control" :placeholder="canWrite ? '내용을 입력하세요' : '이벤트 기간이 아닙니다.'" :disabled="!canWrite"></textarea>
              <input ref="fileInput" type="file" @change="uploadFile" style="display: none;"/>

              <div class="content-files">
                <div v-for="(file, index) in files" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="index" @click="removeFile(index)">
                  <div class="file-link">
                    <img :src="file.url" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="canWrite" class="comment-bottom">
              <button class="kb-btn kb-btn-primary" @click="clickBoardAdd"><span class="text">등록</span></button>
            </div>
          </template>

        </article>
      </div>
    </div>

    <div v-if="loadings.board" class="ssm-comment comment-container" style="padding-top:0px; border-top:0px;">
      <h1 style="font-size:20px;">우리들의 숨고 Talk</h1>
      <input ref="fileInputInEdit" type="file" @change="uploadFileInEdit" style="display: none;"/>
      <div class="comment-wrapper">
        <div v-if="!isMobile" class="comment-list-top"></div>
        <ul class="comment-list">
          <!-- comment-item -->
          <li v-for="(item, index) in items" class="comment-item" :key="index">
            <!-- comment -->
            <article class="comment">
              <div class="comment-avatar">
                <div class="avatar">
                  <CommonTypeImage
                      :img-src="item.imgUrl"
                      theme="profile"
                  />
                </div>
              </div>

              <template v-if="!isMobile">
                <div class="comment-contents">
                  <div class="content-metadata">
                    <div class="metadata">{{ item.lrnerNm }} ・ {{ item.deptNm }}</div>
                    <div class="metadata metadata-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                  </div>

                  <template v-if="item.editMode">
                    <div class="content-form">
                      <textarea v-model.trim="item.cn" class="kb-form-edit-control" placeholder="내용을 입력하세요"></textarea>
                    </div>
<!--                    <div class="content-files">-->
<!--                      <div v-for="(file, idx) in item.alreadyFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'alreadyFiles', idx)">-->
<!--                        <div class="file-link"><img :src="file.url" alt=""></div>-->
<!--                      </div>-->
<!--                      <div v-for="(file, idx) in item.addedFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'addedFiles', idx)">-->
<!--                        <div class="file-link"><img :src="file.url" alt=""></div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="board-edit-actions">
                      <button class="btn" @click="changeMode(index)"><span class="text">취소</span></button>
                      <button class="kb-btn kb-btn-primary" @click="clickBoardChange(index)"><span class="text">수정</span></button>
<!--                      <button class="kb-btn kb-btn-dark" @click="clickFileInputInEdit(index)"><span class="text">사진</span></button>-->
                    </div>
                  </template>
                  <template v-else>
                    <div class="content-text">
                      <p class="text" v-html="getRefinedText(item.originCn)"></p>
                    </div>
<!--                    <div class="content-files">-->
<!--                      <div v-for="(file, idx) in item.files" class="file" style="height: 100%; margin-right: 16px;" :key="idx">-->
<!--                        <div class="file-link"><img :src="file.url" alt=""></div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </template>

                  <div class="content-actions">
                    <button class="kb-btn-like" @click="toggleBoardLike(index)"><i class="icon-like2" :class="{'is-active': item.isLike}"></i><span class="text">{{ item.likeCnt > 0 ? item.likeCnt : 0 }}</span></button>
                    <button class="kb-btn-comment" @click="toggleCommentInput(index)"><i class="icon-comment2"></i><span class="text">{{ item.comments.length }}</span></button>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="comment-contents mt-3 mb-3">
                  <template v-if="item.editMode">
                    <div class="content-form">
                      <textarea v-model.trim="item.cn" class="kb-form-edit-control" placeholder="내용을 입력하세요"></textarea>
                    </div>
                    <div class="content-files">
                      <div v-for="(file, idx) in item.alreadyFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'alreadyFiles', idx)">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                      <div v-for="(file, idx) in item.addedFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'addedFiles', idx)">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                    </div>
                    <div class="board-edit-actions">
                      <button class="btn" @click="changeMode(index)"><span class="text">취소</span></button>
                      <button class="kb-btn kb-btn-primary" @click="clickBoardChange(index)"><span class="text">수정</span></button>
                      <button class="kb-btn kb-btn-dark" @click="clickFileInputInEdit(index)"><span class="text">사진</span></button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="content-text">
                      <p class="text" v-html="getRefinedText(item.originCn)"></p>
                    </div>
                    <div class="content-files">
                      <div v-for="(file, idx) in item.files" class="file" style="margin-right: 16px;" :key="idx">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                    </div>
                  </template>
                  <div class="content-row row-bottom">
                    <div class="content-actions">
                      <button class="kb-btn-like" @click="toggleBoardLike(index)"><i class="icon-like" :class="{'is-active': item.isLike}"></i><span class="text">{{ item.likeCnt > 0 ? item.likeCnt : 0 }}</span></button>
                      <button class="kb-btn-comment" @click="toggleCommentInput(index)"><i class="icon-comment"></i><span class="text">{{ item.comments.length }}</span></button>
                    </div>
                    <div class="content-metadata">
                      <div class="metadata">{{ item.lrnerNm }} ・ {{ item.deptNm }}</div>
                      <div class="metadata metadata-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-if="session.lrnerId === item.lrnerId && !item.editMode && canWrite" class="comment-actions">
                <div class="dropdown" :class="{'is-active': item.toggle}">
                  <button class="dropdown-btn kb-btn-actions" @click.stop="toggleDropdown(index)"><i class="icon-menu"></i></button>
                  <div class="dropdown-target" style="top: 24px;">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index)"><span class="dropdown-option-text">수정</span></a></li>
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickBoardRemove(index)"><span class="dropdown-option-text">삭제</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <!-- //comment -->
            <!-- ssm-comment -->

            <div class="ssm-comment comment-container" :class="{'is-active': item.addComment}">
              <div v-if="item.addComment"  class="comment-write">
                <div class="kb-form-comment">
                  <input v-model.trim="item.cmnt" type="text" class="kb-form-comment-input" placeholder="댓글 달기" :disabled="!canWrite" @keyup.enter="clickCommentAdd(index)">
                  <button class="kb-btn kb-btn-secondary kb-btn-comment" :disabled="!canWrite" @click="clickCommentAdd(index)">
                    <span class="text">댓글</span>
                  </button>
                </div>
              </div>
              <div v-if="item.comments.length > 0" class="comment-wrapper">
                <ul class="comment-list">
                  <!-- comment-item -->
                  <li v-for="(comment, idx) in item.comments" class="comment-item" :key="`${index}-${idx}`">
                    <article class="comment">
                      <div class="comment-avatar">
                        <div class="avatar">
                          <CommonTypeImage
                              :img-src="comment.cmntImgUrl"
                              theme="profile"
                          />
                        </div>
                      </div>
                      <div class="comment-contents">
                        <div class="content-text" :class="{'comment-edit-mode': comment.editMode}">
                          <template v-if="comment.editMode">
                            <div class="comment-form">
                              <div>
                                <input v-model.trim="comment.cmnt" type="text" class="comment-field" @keyup.enter="clickChange(index, idx)"/>
                              </div>
                            </div>
                          </template>
                          <p v-else class="text">{{ comment.originCmnt }}</p>
                        </div>
                        <div class="content-metadata">
                          <div class="metadata">{{ comment.cmntLrnerNm }} ・ {{ comment.cmntDeptNm }}</div>
                          <div class="metadata metadata-date">{{ timestampToDateFormat(comment.cmntRegDt, 'yyyy.MM.dd hh:mm') }}</div>
                        </div>
                        <div v-if="comment.editMode" class="comment-actions">
                          <button class="btn" @click="changeMode(index, idx)"><span class="text">취소</span></button>
                          <button class="btn" @click="clickChange(index, idx)"><span class="text">수정</span></button>
                        </div>
                      </div>
                      <div v-if="session.lrnerId === comment.cmntLrnerId && !comment.editMode && canWrite" class="comment-actions">
                        <div class="dropdown" :class="{'is-active': comment.toggle}">
                          <button class="dropdown-btn kb-btn-actions" @click.stop="toggleDropdown(index, idx)"><i class="icon-menu"></i></button>
                          <div class="dropdown-target">
                            <div class="dropdown-box">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index, idx)"><span class="dropdown-option-text">수정</span></a></li>
                                <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickRemove(index, idx)"><span class="dropdown-option-text">삭제</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </li>
                </ul>
              </div>
            </div>
            <!-- //ssm-comment -->
          </li>
        </ul>
        <div class="comment-list-bottom">
          <CommonPaginationFront
              :paging="paging"
              :page-func="pagingFunc"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProfileImg from '@/components/common/ProfileImg';
import {computed, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {clearTargetTimeout, getItem, isSuccess, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {closeToggles, convertCloudFrontUrl, getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_CRSE_DIST_GRP_SEQ_LIST} from '@/store/modules/course/course';
// import SortButtonSelect from '@/components/common/SortButtonSelect';
import {
  ACT_DELETE_LRN_BOARD,
  ACT_DELETE_LRN_BOARD_CMNT,
  ACT_DELETE_LRN_BOARD_LK,
  ACT_GET_LEARN_BOARD_WITH_COMMENT,
  ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST,
  ACT_INSERT_LRN_BOARD,
  ACT_INSERT_LRN_BOARD_CMNT,
  ACT_INSERT_LRN_BOARD_LK,
  ACT_UPDATE_LRN_BOARD,
  ACT_UPDATE_LRN_BOARD_CMNT,
  ACT_UPLOAD_LEARN_BOARD_IMAGE,
} from '@/store/modules/board/board';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  confirmDeleteComment,
  confirmInsertComment,
  confirmUpdateComment,
} from '@/assets/js/modules/board/comment-common';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SumgoBoard',
  components: {
    CommonTypeImage,
    // SortButtonSelect,
    CommonPaginationFront,
    ProfileImg,
  },
  props: {
    targetItem: Object,
  },
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const {showConfirm, showMessage} = useAlert();
    const currentTimestamp = new Date().getTime();

    const session = computed(() => store.state.auth.session);
    const fileInput = ref(null);
    const fileInputInEdit = ref(null);
    const editingIndex = ref(-1);

    const contents = ref('');
    const files = ref([]);

    const loadings = reactive({
      seq : false,
      board: false
    });

    const toggles = reactive({
      seq: false,
      file: false,
    });

    const seqs = ref([]);
    const items = ref([]);

    const paging = reactive({
      pageNo: 1,
      pageSize: 8,
      // pageSize: 1,
      totalCount: 0
    });

    const distCrseGrpSeqSn = ref(0);
    const targetCrseSeq = computed(() => {
      const target = seqs.value.find(x => distCrseGrpSeqSn.value === x.distCrseGrpSeqSn);
      if(target) return target;
      return {distCrseGrpSeqSn: 0}
    });

    const canWrite = computed(() => targetCrseSeq.value.distCrseGrpSeqSn > 0 && targetCrseSeq.value.bgngDt <= currentTimestamp && targetCrseSeq.value.endDt >= currentTimestamp);

    const addBoard = () => {
      store.dispatch(`board/${ACT_INSERT_LRN_BOARD}`, {
        distCrseSn: targetCrseSeq.value.distCrseSn,
        distCrseGrpSeqSn: distCrseGrpSeqSn.value,
        cn: contents.value,
        files: files.value
      }).then(res => {
        if(isSuccess(res)) {
          contents.value = '';
          files.value = [];
          paging.pageNo = 1;
          getBoardWithCommentList();
          showMessage('등록이 완료되었습니다.');
        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    const clickBoardAdd = () => {
      if(contents.value && contents.value.length <= 300) {
        showConfirm({
          title: '숨고Talk',
          text: '작성된 내용을 등록하시겠습니까?',
          callback: addBoard
        });
      }
      else if(contents.value.length > 300) {
        showMessage('내용을 300자 이하로 작성해주세요.');
      }
      else {
        showMessage('내용을 입력해주세요.(300자 제한)');
      }
    }

    const changeBoard = (index) => {
      store.dispatch(`board/${ACT_UPDATE_LRN_BOARD}`, {
        lrnPostSn: items.value[index].lrnPostSn,
        params: {
          cn: items.value[index].cn,
          files: items.value[index].addedFiles,
          removedFiles: items.value[index].removedFiles,
        }
      }).then(res => {
        if(isSuccess(res)) {
          // 수정된 부분만 갈아껴준다.
          shiftBoardWithComment(index);
          showMessage('수정이 완료되었습니다.');
        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    const clickBoardChange = (index) => {
      if(items.value[index].cn) {
        showConfirm({
          title: '숨고 Talk',
          text: '해당 내용을 수정하시겠습니까?',
          callback: () => {changeBoard(index)}
        });
      } else {
        showMessage('내용을 입력해주세요.');
      }
    }

    const removeBoard = (index) => {
      store.dispatch(`board/${ACT_DELETE_LRN_BOARD}`, items.value[index].lrnPostSn).then(res => {
        if(isSuccess(res)){
          showMessage(`해당 글이 삭제되었습니다.`);
          paging.pageNo = 1;
          getBoardWithCommentList();
        }else{
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    const clickBoardRemove = (index) => {
      showConfirm({
        title: '숨고 Talk',
        text: '해당 글을 삭제하시겠습니까?',
        callback: () => {removeBoard(index)}
      });
    }

    const toggleCommentInput = (index) => {
      items.value[index].addComment = !items.value[index].addComment;
    }

    const clickFileInput = () => {
      fileInput.value.click();
    }

    const getEventFile = (file, res) => {
      const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1);
      return {
        lrnPostFileAtchSn: 0, itnPath: res.path, etnPath: res.path, url: res.url,
        fileNm: file.name,
        fileTyCdDcd: fileExt, fileTyNm: fileExt,
        fileSz: file.size
      }
    }

    const uploadFile = async () => {
      if(files.value.length > 2) {
        showMessage('이미지는 최대 3개까지만 등록할 수 있습니다.');
        fileInput.value.value = '';
        return;
      }

      if(fileInput.value.files && fileInput.value.files.length > 0) {
        const file = fileInput.value.files[0];
        // 이미지 파일만 체크
        if(file.type.includes('image')){
          const uploadRes = await store.dispatch(`board/${ACT_UPLOAD_LEARN_BOARD_IMAGE}`, {contentsType: 'event', files: [file]});
          files.value.push(getEventFile(file, uploadRes));

        }else {
          showMessage('이미지는 파일만 등록해 주세요.');
        }
        fileInput.value.value = '';
      }
    }

    const clickFileInputInEdit = (index) => {
      editingIndex.value = index;
      fileInputInEdit.value.click();
    }

    const uploadFileInEdit = async () => {
      if(items.value[editingIndex.value].addedFiles.length + items.value[editingIndex.value].alreadyFiles.length > 2) {
        showMessage('이미지는 최대 3개까지만 등록할 수 있습니다.');
        fileInputInEdit.value.value = '';
        return;
      }
      if(fileInputInEdit.value.files && fileInputInEdit.value.files.length > 0) {
        const file = fileInputInEdit.value.files[0];
        // 이미지 파일만 체크
        if(file.type.includes('image')){
          const uploadRes = await store.dispatch(`board/${ACT_UPLOAD_LEARN_BOARD_IMAGE}`, {contentsType: 'event', files: [file]});
          items.value[editingIndex.value].addedFiles.push(getEventFile(file, uploadRes));
        }else {
          showMessage('이미지는 파일만 등록해 주세요.');
        }
        fileInputInEdit.value.value = '';
      }
    }


    const removeFile = (fileIdx) => {
      showConfirm({
        title: '이미지 삭제',
        text: '선택된 파일을 삭제하시겠습니까?',
        callback: () => {
          files.value.splice(fileIdx, 1);
        }
      });
    }

    // targetKey => alreadyFiles, addedFiles
    const removedFileInUpdate = (index, targetKey, fileIdx) => {
      showConfirm({
        title: '이미지 삭제',
        text: '선택된 파일을 삭제하시겠습니까?',
        callback: () => {
          if(items.value[index][targetKey][fileIdx].lrnPostFileAtchSn > 0) {
            items.value[index].removedFiles.push(items.value[index][targetKey][fileIdx]);
          }
          items.value[index][targetKey].splice(fileIdx, 1);
        }
      });
    }

    const refineBoard = (item) => {
      item.originCn = item.cn;
      item.comments = item.comments.map(y => ({...y, originCmnt: y.cmnt}));
      item.files = item.files.map(y => ({...y, url: convertCloudFrontUrl(session.value.itn, y, 'itnPath', 'etnPath')}));
      item.alreadyFiles = item.files;
      item.addedFiles = [];
      item.removedFiles = [];
      item.isLike = (item.myLikeCnt > 0);
    }

    const shiftBoardWithComment = (index) => {
      store.dispatch(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT}`, items.value[index].lrnPostSn).then(res => {
        if(lengthCheck(res)) {
          const item = getItem(res);
          item.imgUrl = items.value[index].imgUrl;
          refineBoard(item)
          // edit-mode, toggle 자동 초기화 된다...
          items.value[index] = item;
        }
      });
    }

    const getBoardWithCommentList = (isInit) => {
      getListFunc(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST}`, {distCrseGrpSeqSn: distCrseGrpSeqSn.value, ...paging}, items, paging, () => {
        if(isInit) loadings.board = true;
        items.value.forEach(x => {refineBoard(x);})
      });
    }

    const getCourseSequenceList = (isInit) => {
      getListFunc(`course/${ACT_GET_CRSE_DIST_GRP_SEQ_LIST}`, {distCrseSn: props.targetItem.distCrseSn}, seqs, null, () => {
        if(isInit) {
          // 현재 사용되는 회차를 등록
          if (seqs.value.length > 0) {
            const target = seqs.value.find(x => x.bgngDt <= currentTimestamp && x.endDt >= currentTimestamp);
            if (target) {
              distCrseGrpSeqSn.value = target.distCrseGrpSeqSn;
            } else {
              distCrseGrpSeqSn.value = seqs.value[0].distCrseGrpSeqSn;
            }

            loadings.seq = true;
          }
        }

        if(distCrseGrpSeqSn.value > 0) {
          getBoardWithCommentList(isInit);
        }
      });
    }

    const changeSeq = () => {
      paging.pageNo = 1
      getBoardWithCommentList();
    }

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getBoardWithCommentList();
    }

    const toggleDropdown = (index, idx) => {
      if(idx === undefined) {
        items.value.forEach((x, i) => {
          if(i === index) {
            x.toggle = !x.toggle;
          } else {
            if(x.toggle) x.toggle = false;
          }
          x.comments.forEach(y => {
            if(y.toggle) y.toggle = false;
          });
        });
      } else {
        items.value.forEach((x, i) => {
          if(x.toggle) x.toggle = false;
          x.comments.forEach((y, j) => {
            if(i === index && j === idx) {
              y.toggle = !y.toggle
            } else{
              if(y.toggle) y.toggle = false;
            }
          });
        });
      }
    }

    const changeMode = (index, idx) => {
      if(idx === undefined) {
        if(!items.value[index].editMode){
          // edit 모드가 풀렸을 때 처리
          items.value[index].cn = items.value[index].originCn;
          items.value[index].alreadyFiles = items.value[index].files;
          items.value[index].removedFiles = [];
          items.value[index].toggle = false;
        }
        items.value[index].editMode = !items.value[index].editMode;
      } else{
        if(!items.value[index].comments[idx].editMode){
          items.value[index].comments[idx].cmnt = items.value[index].comments[idx].originCmnt;
          items.value[index].comments[idx].toggle = false;
        }
        items.value[index].comments[idx].editMode = !items.value[index].comments[idx].editMode;
      }
    }

    const isLoading = ref(false);

    const clickCommentAdd = (index) => {
      const params = {
        lrnPostSn: items.value[index].lrnPostSn,
        params: {cmnt: items.value[index].cmnt}
      };

      confirmInsertComment(`board/${ACT_INSERT_LRN_BOARD_CMNT}`, params, isLoading.value, items.value[index].cmnt, async (res) => {
        if(res.lrnPostCmntSn > 0) {
          const pushItem = {
            lrnPostCmntSn: res.lrnPostCmntSn,
            cmnt: items.value[index].cmnt,
            originCmnt: items.value[index].cmnt,
            cmntRegDt: new Date().getTime(),
            cmntLrnerId: session.value.lrnerId,
            cmntLrnerNm: session.value.lrnerNm,
            cmntDeptNm: session.value.deptNm,
            cmntImgUrl: store.state.my.profileImgUrl
          }

          items.value[index].comments.push(pushItem);
          items.value[index].cmnt = '';
        }
      });
    }

    const clickChange = (index, idx) => {
      items.value[index].comments[idx].toggle = false;

      const params = {
        lrnPostCmntSn: items.value[index].comments[idx].lrnPostCmntSn,
        params: {cmnt: items.value[index].comments[idx].cmnt}
      };

      confirmUpdateComment(`board/${ACT_UPDATE_LRN_BOARD_CMNT}`, params, isLoading.value, items.value[index].comments[idx].cmnt, () => {
        items.value[index].comments[idx].originCmnt = items.value[index].comments[idx].cmnt;
        items.value[index].comments[idx].editMode = false;
      });
    }

    const clickRemove = (index, idx) => {
      items.value[index].comments[idx].toggle = false;
      confirmDeleteComment(`board/${ACT_DELETE_LRN_BOARD_CMNT}`, items.value[index].comments[idx].lrnPostCmntSn, isLoading.value, items.value[index].comments);
    }

    const closeAllToggle = () => {
      closeToggles(toggles);

      items.value.forEach(x => {
        if(x.toggle) x.toggle = false;
        x.comments.forEach(y => {
          if(y.toggle) y.toggle = false;
        });
      });
    }

    const toggleBoardLike = (index) => {
      if(!canWrite.value) return;

      clearTargetTimeout(items.value[index].debounce);
      items.value[index].debounce = setTimeout(() => {
        store.dispatch(`board/${items.value[index].isLike ? ACT_INSERT_LRN_BOARD_LK : ACT_DELETE_LRN_BOARD_LK}`, items.value[index].lrnPostSn).then(() => {});
      }, 500);

      if(items.value[index].isLike) {
        items.value[index].likeCnt -= 1;
      } else {
        items.value[index].likeCnt += 1;
      }
      items.value[index].isLike = !items.value[index].isLike;
    }

    const getRefinedText = cn => {
      if (cn) {
        // \n을 <br>로 치환
        cn = cn.replace(/(?:\r\n|\r|\n)/g, '<br>');
        // return cn.replace(/(<([^>]+)>)/gi, "");
      }
      return cn;
    }

    getCourseSequenceList(true);

    watch(() => props.targetItem, () => {
      if(props.targetItem && props.targetItem.distCrseSn > 0) {
        paging.pageNo = 1;
        getCourseSequenceList(true);
      }
    });

    return {
      fileInput,
      fileInputInEdit,
      session,
      toggles,
      loadings,
      seqs,
      distCrseGrpSeqSn,
      contents,
      files,
      canWrite,
      items,
      paging,
      clickFileInput,
      uploadFile,
      clickFileInputInEdit,
      uploadFileInEdit,
      toggleCommentInput,
      pagingFunc,
      clickBoardAdd,
      clickBoardChange,
      clickBoardRemove,

      toggleDropdown,
      changeMode,
      clickCommentAdd,
      clickChange,
      clickRemove,

      changeSeq,
      removeFile,
      removedFileInUpdate,
      getRefinedText,

      timestampToDateFormat,
      closeAllToggle,
      toggleBoardLike,
      isMobile,

    }
  }
};
</script>
